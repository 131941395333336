import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { StateNames } from '../models/vendor.model';
import { ReferalFormService } from '../shared/referal-form.service';
import { VendorService } from '../shared/vendor.service';

@Component({
  selector: 'app-service-location',
  templateUrl: './service-location.component.html',
  styleUrls: ['./service-location.component.css','../shared/css/common.css']
})
export class ServiceLocationComponent implements OnInit {

  serviceForm = this.serviceFormBuilder.group({});
  serviceFormData: any;
  formdata : any | null;
  payorkey: number;
  title: string = "Provider";
  isformloaded: boolean = false;
  statelist: StateNames[] = [];

  constructor(
    private serviceFormBuilder: FormBuilder, 
    private router: Router,
    private vendorService: VendorService,
    private cdref: ChangeDetectorRef
  ) {
    this.formdata = JSON.parse(sessionStorage.getItem("activeformdata")??"{}");
  this.serviceFormData = this.formdata.serviceLocationDetails; 
  this.payorkey = parseInt(sessionStorage.getItem("payorkey")?? "0"); 
   }

  ngOnInit(): void {
    if(this.formdata.witnessDetails.controls.find((x: { name: string; }) => x.name == "involvedType").value){
      this.title = this.formdata.witnessDetails.controls.find((x: { name: string; }) => x.name == "involvedType").options.find(
        (y: { value: string; })  => 
        y.value == this.formdata.witnessDetails.controls.find((x: { name: string; }) => x.name == "involvedType").value).key;
    }
    this.vendorService.getStateNames().subscribe((result)=>{
      this.statelist = result;
      this.serviceFormData.controls.find((x: { name: string; }) => x.name == "sState").options = result.map(x => {return { "value" : x.Abbreviation, "key" : x.Name}});
    })
    this.vendorService.getRequiredFields(this.payorkey).subscribe((result)=>{      
      this.serviceFormData.controls.find((x: { name: string; }) => x.name == "sAddress1").validators.required = result.ProviderAddress;
      this.serviceFormData.controls.find((x: { name: string; }) => x.name == "sAddress2").validators.required = result.ProviderAddress;
      this.serviceFormData.controls.find((x: { name: string; }) => x.name == "sCity").validators.required = result.ProviderCity;
      this.serviceFormData.controls.find((x: { name: string; }) => x.name == "sState").validators.required = result.ProviderState;
      this.serviceFormData.controls.find((x: { name: string; }) => x.name == "sZip").validators.required = result.ProviderZIP;
      this.serviceFormData.controls.find((x: { name: string; }) => x.name == "sPhoneNumber").validators.required = result.ProviderPhoneNumber;
      this.serviceFormData.controls.find((x: { name: string; }) => x.name == "sFax").validators.required = result.ProviderFaxNumber;
      this.serviceFormData.controls.find((x: { name: string; }) => x.name == "sEmail").validators.required = result.ProviderEmailAddress;        

      this.isformloaded = true;
      this.cdref.detectChanges();
    });
  }
  

  serviceDetailsSubmit() {
    if (this.serviceForm.valid) {
      this.formdata.serviceLocationDetails = this.serviceFormData;
      sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));
      this.router.navigate(['validateParticipant']);
    }
  }
  back() {
    this.router.navigate(['provider']);
  }

  valueChange(data: any) {
    if(data.name == 'sZip') {
      this.serviceFormData.controls.find((x: { name: string; }) => x.name == data.name).value =data.value;
    }
    else if(data.name == 'sState') {
      this.vendorService.getStateCounties(this.statelist.find( y => y.Id == data.value.target.selectedIndex)?.Abbreviation?? "").subscribe((result)=>{
        this.serviceFormData.controls.find((x: { name: string; }) => x.name == "sCounty").options = result.map(x => {return { "value" : x.County, "key" : x.County}});
      })
      this.serviceFormData.controls.find((x: { name: string; }) => x.name == "sCounty").disabled = false;
    }
  }

}
