import {Injectable, Inject} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import { EMPTY, Observable, catchError } from 'rxjs';
import { ErrorHandlerWrapper } from 'src/app/shared/error-handler/error-handler.wrapper';

@Injectable()
export class ReqInterceptor implements HttpInterceptor {

  constructor(private errorHandler: ErrorHandlerWrapper){    
  }   

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {      
    
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);
         return EMPTY;
      })
    );
  }
}
