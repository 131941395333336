import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ReferalFormService } from '../shared/referal-form.service';
import { VendorService } from '../shared/vendor.service';

@Component({
  selector: 'app-parcticipant-validation',
  templateUrl: './parcticipant-validation.component.html',
  styleUrls: ['./parcticipant-validation.component.css','../shared/css/common.css']
})
export class ParcticipantValidationComponent implements OnInit {

  constructor(
    private validateParticipantFormBuilder: FormBuilder, 
    private router: Router,
    private validateParticipantService: ReferalFormService,
    private vendorService: VendorService,
    private cdref: ChangeDetectorRef
  ) {
    this.formdata = JSON.parse(sessionStorage.getItem("activeformdata")??"");
    this.validateParticipantFormData = this.formdata.validateParticipant;   
    this.payorkey = parseInt(sessionStorage.getItem("payorkey")?? "0"); 
   }  

  formdata : any | null;
  validateParticipantForm = this.validateParticipantFormBuilder.group({});
  validateParticipantFormData: any;
  participantvalidationFormData: any;
  payorkey : number;
  title: string = "Provider";



  ngOnInit(): void {

    // if(this.formdata.witnessDetails.controls.find((x: { name: string; }) => x.name == "involvedType").value){
    //   this.title = this.formdata.witnessDetails.controls.find((x: { name: string; }) => x.name == "involvedType").options.find(
    //     (y: { value: string; })  => 
    //     y.value == this.formdata.witnessDetails.controls.find((x: { name: string; }) => x.name == "involvedType").value).key;
    //     this.validateParticipantFormData.controls.find((x: { name: string; }) => x.name === 'vpIsParticipant').label = "Does this referral involve a specific "+this.title +" ?";
    //     this.validateParticipantFormData.subcontrols.find((x: { name: string; }) => x.name === 'vpIsreferring').label = "Was the "+this.title +" also the witness or external referring party?";
    // }
   
    this.vendorService.getLeadSetup(this.payorkey).subscribe((leadsetupresult)=>{ 
      this.validateParticipantFormData.controls.find((x: { name: string; }) => x.name === 'vpIsParticipant').label = "Does this referral involve a specific "+leadsetupresult.PatientDescription +" ?";
      this.validateParticipantFormData.subcontrols.find((x: { name: string; }) => x.name === 'vpIsreferring').label = "Was the "+leadsetupresult.PatientDescription+" also the witness or external referring party?";
      
    }); 
  }

  validateParticipantDetailsSubmit() {
    if (!(this.validateParticipantForm.invalid && this.validateParticipantForm.get('isExternalReferal')?.value != 'NO')) {
      this.formdata.validateParticipant = this.validateParticipantFormData;
      sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));
      if(this.validateParticipantForm.get('vpIsParticipant')?.value !== 'YES') {
        this.router.navigate(['summary']);
      } else{
        this.router.navigate(['particpant']);
      }
    }
  }

  back() {
    this.formdata.validateParticipant = this.validateParticipantFormData; 
    sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));
    this.router.navigate(['service']);
  }

  valueChangeEvent(data: any) {
    if(data.name === 'vpIsParticipant') {
      const keys = Object.keys(this.validateParticipantForm.value);
      keys.forEach(element => {
        if(element!=='vpIsParticipant') {
          this.validateParticipantForm.removeControl(element);
        }
      });
    }
  }

}
