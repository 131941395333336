
<div class="row main-home">
<div class="col-md-6 text-end" *ngIf="tabIndex==0">
    <button type="button" class="btn btn-default">Instructions</button>
</div>
<div class="col-md-6 text-end" *ngIf="tabIndex>0">
    <button type="button" class="btn btn-default" (click)="downTabCount()" style="font-weight : bold;" >Back</button>
</div>
<div class="col-md-6">
    <button type="button" class="btn btn-primary" (click)="addTabCount()">Next</button>
</div>


<app-user-details *ngIf="tabIndex==0"></app-user-details>
<app-referal-details *ngIf="tabIndex==1"></app-referal-details>
<app-witness-details *ngIf="tabIndex==2"></app-witness-details>

</div>
