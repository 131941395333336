import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ReferalFormService } from '../shared/referal-form.service';
import { VendorService } from '../shared/vendor.service';
import { NgxSpinnerService } from 'ngx-spinner'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-agreement-details',
  templateUrl: './agreement-details.component.html',
  styleUrls: ['./agreement-details.component.css','../shared/css/common.css']
})
export class AgreementDetailsComponent implements OnInit {

  
  @Input()
  showCaptcha: boolean | undefined;  
  captcha: string;                              // empty = not yet proven to be a human, anything else = human
  email: string;
  formdata: any;
  payorkey: number;
  isMember: boolean = false;
  orglist: any = [];
  referralTypeList: any = [];
  referralInvolvedTypeList: any = [];
  sitekey: string;
  constructor(
    private agreementFormBuilder: FormBuilder, 
    private router: Router,
    private vendorService: VendorService,
    private cdref: ChangeDetectorRef,
    private ngxsippner : NgxSpinnerService) { 
      this.captcha = '';
      this.email = 'Secret@email.com';
      this.formdata = JSON.parse(sessionStorage.getItem("activeformdata")??"{}");
  this.agreementFormData = this.formdata.agreementDetails;
  this.payorkey = parseInt(sessionStorage.getItem("payorkey")?? "0");
  this.sitekey = environment.capchaSiteKey; 
  }

  ngOnInit(): void {
    this.vendorService.getInvolvedPartiesLookup(this.payorkey).subscribe((result)=>{
     this.referralInvolvedTypeList = result.map(x => {return { "value" : x.Id, "key" : x.Title}});
      let selectedpartyId = this.formdata.witnessDetails.controls.find((x: { name: string; }) => x.name == "involvedType").value;
      let selectedparty = result.find(x => x.Id == selectedpartyId);
      if(selectedparty){
        this.isMember = selectedparty.IsMember || !selectedparty.IsProvider;
      }
    })

    this.vendorService.getReferringSourcesLookup(this.payorkey).subscribe((result)=>{          
      this.orglist = result.map(x => {return { "value" : x.Id, "key" : x.Title}});
    })

    this.vendorService.getReferralTypesLookup(this.payorkey).subscribe((result)=>{
      this.referralTypeList = result.map(x => {return { "value" : x.Id, "key" : x.Title}});
    })

    
  }

  resolved(captchaResponse: string) {
      this.captcha = captchaResponse;
      console.log('resolved captcha with response: ' + this.captcha);
  }

  agreementForm = this.agreementFormBuilder.group({});
  agreementFormData: any;
  
  agreementDetailsSubmit() {
    if (this.showCaptcha || this.captcha) {
    this.ngxsippner.show();
    this.formdata = JSON.parse(sessionStorage.getItem("activeformdata")??"{}");
    this.formdata.agreementDetails = this.agreementFormData; 
    sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));
    var data = {
      Description:this.formdata.summaryDetails.controls[0].value,
      AlternateId:this.formdata.witnessDetails.controls.find((x: { name: any; }) => x.name == "trackingNumber").value,
      Tilte:this.formdata.userDetails.controls.find((x: { name: any; }) => x.name == "title").value,
      OrganizationId:this.formdata.userDetails.controls.find((x: { name: any; }) => x.name == "orgName").value,
      OrganizationName:this.orglist.find((xy: { value: any; })  => xy.value == this.formdata.userDetails.controls.find((xy : any) => xy.name == "orgName").value)?.key,
      FirstName:this.formdata.userDetails.controls.find((x: { name: any; }) => x.name == "firstName").value,
      LastName:this.formdata.userDetails.controls.find((x: { name: any; }) => x.name == "lastName").value,
      Phone:this.formdata.userDetails.controls.find((x: { name: any; }) => x.name == "phone").value,
      Email:this.formdata.userDetails.controls.find((x: { name: any; }) => x.name == "email").value,
      LeadTypeId:this.isMember ? 2 : 1,
      SuspectActivityFrom:this.formdata.witnessDetails.controls.find((x: { name: any; }) => x.name == "incidentStartDt").value,
      SuspectActivityTo:this.formdata.witnessDetails.controls.find((x: { name: any; }) => x.name == "incidentEndDt").value,

      AmountAtRisk:this.formdata.witnessDetails.controls.find((x: { name: any; }) => x.name == "estimatedAmount").value,
      LeadReasons:this.formdata.witnessDetails.controls.find((x: { name: any; }) => x.name == "referalType").value,
      SourceCode:this.isMember ? "" : this.formdata.providerDetails.controls.find((x: { name: any; }) => x.name == "pOrgName").value,
      MemberOrProviderFirstName:this.isMember ? 
      this.formdata.memberDetails.controls.find((x: { name: any; }) => x.name == "mFirstName").value
      : this.formdata.providerDetails.controls.find((x: { name: any; }) => x.name == "pFirstName").value,
      MemberOrProviderLastName:this.isMember ? 
      this.formdata.memberDetails.controls.find((x: { name: any; }) => x.name == "mLastName").value
      : this.formdata.providerDetails.controls.find((x: { name: any; }) => x.name == "pLastName").value,
      MemberOrProviderPhone:this.isMember ? 
      this.formdata.memberDetails.controls.find((x: { name: any; }) => x.name == "mPhoneNumber").value
      : this.formdata.serviceLocationDetails.controls.find((x: { name: any; }) => x.name == "sPhoneNumber").value,
      MemberOrProviderEmail:this.isMember ? 
      this.formdata.memberDetails.controls.find((x: { name: any; }) => x.name == "mEmail").value
      : this.formdata.serviceLocationDetails.controls.find((x: { name: any; }) => x.name == "sEmail").value,
      programPlanType : this.formdata.memberDetails.controls.find((x: { name: any; }) => x.name == "mProgram").value,
      ProgramDateOfBirth : this.formdata.memberDetails.controls.find((x: { name: any; }) => x.name == "mDOB").value,
      MemberOrProviderAddress1:this.isMember ? 
      this.formdata.memberDetails.controls.find((x: { name: any; }) => x.name == "mAddress1").value
      : this.formdata.serviceLocationDetails.controls.find((x: { name: any; }) => x.name == "sAddress1").value,
      MemberOrProviderAddress2:this.isMember ? 
      this.formdata.memberDetails.controls.find((x: { name: any; }) => x.name == "mAddress2").value
      : this.formdata.serviceLocationDetails.controls.find((x: { name: any; }) => x.name == "sAddress2").value,
      MemberOrProviderCity:this.isMember ? 
      this.formdata.memberDetails.controls.find((x: { name: any; }) => x.name == "mCity").value
      : this.formdata.serviceLocationDetails.controls.find((x: { name: any; }) => x.name == "sCity").value,
      MemberOrProviderState:this.isMember ? 
      this.formdata.memberDetails.controls.find((x: { name: any; }) => x.name == "mState").value
      : this.formdata.serviceLocationDetails.controls.find((x: { name: any; }) => x.name == "sState").value,
      MemberOrProviderZipCode:this.isMember ? 
      this.formdata.memberDetails.controls.find((x: { name: any; }) => x.name == "mZip").value
      : this.formdata.serviceLocationDetails.controls.find((x: { name: any; }) => x.name == "sZip").value,
      MemberOrProviderTaxId:this.isMember ? 
      ""
      : this.formdata.providerDetails.controls.find((x: { name: any; }) => x.name == "pTIN").value,
      MemberOrProviderLicenseNo:this.isMember ? 
      ""
      : this.formdata.providerDetails.controls.find((x: { name: any; }) => x.name == "pLicenseNo").value,
      MemberOrProviderType:this.isMember ? 
      ""
      : this.formdata.providerDetails.controls.find((x: { name: any; }) => x.name == "pType").value,
      MemberOrProviderSpecialty:this.isMember ? 
      ""
      : this.formdata.providerDetails.controls.find((x: { name: any; }) => x.name == "pSpecialty").value,
      MemberOrProviderFax:this.isMember ? ""
      : this.formdata.serviceLocationDetails.controls.find((x: { name: any; }) => x.name == "sFax").value,
      MemberOrProviderCounty:this.isMember ? 
      ""
      : this.formdata.serviceLocationDetails.controls.find((x: { name: any; }) => x.name == "sCounty").value,
      LeadMasterId:this.isMember ? 
      this.formdata.memberDetails.controls.find((x: { name: any; }) => x.name == "mID").value
      : this.formdata.providerDetails.controls.find((x: { name: any; }) => x.name == "pNPInumber").value,
      ProviderId : this.isMember ? "" : this.formdata.providerDetails.controls.find((x: { name: any; }) => x.name == "pProviderID").value,
      DeaNumber :  this.isMember ? "" : this.formdata.providerDetails.controls.find((x: { name: any; }) => x.name == "pDEA").value,
      WebSite :  this.isMember ? "" : this.formdata.providerDetails.controls.find((x: { name: any; }) => x.name == "pWebsite").value,
      QuestionList : this.formdata.questionsDetails.controls,
      FCReferralJson: JSON.stringify(this.formdata),
      TimeZoneUtcDifference: (new Date()).getTimezoneOffset() ,
      ReferalType:this.referralTypeList.find((xz: { value: any; })  => xz.value == this.formdata.witnessDetails.controls.find((x: { name: any; }) => x.name == "referalType").value)?.key,
      ReferalInvolvedType:this.referralInvolvedTypeList.find((xw: { value: any; })  => xw.value == this.formdata.witnessDetails.controls.find((x: { name: any; }) => x.name == "involvedType").value)?.key,
      ReferalTrackingNumber:this.formdata.witnessDetails.controls.find((x: { name: any; }) => x.name == "trackingNumber").value,
      ReferalDetected:this.formdata.witnessDetails.controls.find((x: { name: any; }) => x.name == "detected").value,
      ReferalEstimatedAmount:this.formdata.witnessDetails.controls.find((x: { name: any; }) => x.name == "estimatedAmount").value,
      ReferalOriginalDetectionDt:this.formdata.witnessDetails.controls.find((x: { name: any; }) => x.name == "originalDetectionDt").value,
      ReferalIncidentStartDt:this.formdata.witnessDetails.controls.find((x: { name: any; }) => x.name == "incidentStartDt").value,
      ReferalIncidentEndDt:this.formdata.witnessDetails.controls.find((x: { name: any; }) => x.name == "incidentEndDt").value,
      ReferalState:this.formdata.witnessDetails.controls.find((x: { name: any; }) => x.name == "state").value,
      ReferalCounty:this.formdata.witnessDetails.controls.find((x: { name: any; }) => x.name == "county").value,
      WitnessExternalReferal:this.formdata.extenalReferall.controls.find((x: { name: any; }) => x.name == "isExternalReferal").value,
      WitnesFirstName:this.formdata.extenalReferall.subcontrols.find((x: { name: any; }) => x.name == "wFirstName").value,
      WitnesLastName:this.formdata.extenalReferall.subcontrols.find((x: { name: any; }) => x.name == "wLastName").value,
      WitnesAgencyName:this.formdata.extenalReferall.subcontrols.find((x: { name: any; }) => x.name == "wAgencyName").value,
      WitnesPhone:this.formdata.extenalReferall.subcontrols.find((x: { name: any; }) => x.name == "phone").value,
      WitnesEmail:this.formdata.extenalReferall.subcontrols.find((x: { name: any; }) => x.name == "email").value,
      WitnessRelation: this.formdata.extenalReferall.subcontrols.find((x: { name: any; }) => x.name == "wRelationShip").value,
      
      SpecificPatient: this.formdata.validateParticipant.controls.find((x: { name: any; }) => x.name == "vpIsParticipant").value,

      ParticipantFirstName: this.formdata.participantDetails.controls.find((x: { name: any; }) => x.name == "paFirstName").value,
      ParticipantLastName: this.formdata.participantDetails.controls.find((x: { name: any; }) => x.name == "paLastName").value,
      ParticipantId: this.formdata.participantDetails.controls.find((x: { name: any; }) => x.name == "paID").value,
      ParticipantDOB: this.formdata.participantDetails.controls.find((x: { name: any; }) => x.name == "paDOB").value,
      ParticipantPlanType: this.formdata.participantDetails.controls.find((x: { name: any; }) => x.name == "paPlanType").value,
      ParticipantAdd1: this.formdata.participantDetails.controls.find((x: { name: any; }) => x.name == "paAddress1").value,
      ParticipantAdd2: this.formdata.participantDetails.controls.find((x: { name: any; }) => x.name == "paAddress2").value,
      ParticipantCity: this.formdata.participantDetails.controls.find((x: { name: any; }) => x.name == "paCity").value,
      ParticipantState: this.formdata.participantDetails.controls.find((x: { name: any; }) => x.name == "paState").value,
      ParticipantZip: this.formdata.participantDetails.controls.find((x: { name: any; }) => x.name == "paZip").value,
      ParticipantPhone: this.formdata.participantDetails.controls.find((x: { name: any; }) => x.name == "paPhone").value,
      ParticipantEmail: this.formdata.participantDetails.controls.find((x: { name: any; }) => x.name == "paEmail").value,
    }
    this.vendorService.createLead(this.payorkey, data).subscribe((result)=>{
      this.ngxsippner.hide()
      if(result.IsSuccess){
        this.router.navigate(['final']);
      }else{
        this.ngxsippner.hide();
        localStorage.removeItem("activeformdata");    
        this.router.navigateByUrl("/" + (sessionStorage.getItem("securityCode") ?? ""));
      }
      
    }, error => {this.ngxsippner.hide()});
  
  }
}

  back() {
    this.formdata.agreementDetails = this.agreementFormData; 
    sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));
    this.router.navigate(['summary']);
  }

  captchaEvent(captcha: string) {
    this.captcha = captcha;
  }


}
