import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { HomeComponent } from './home/home.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { ReferalDetailsComponent } from './referal-details/referal-details.component';
import { WitnessDetailsComponent } from './witness-details/witness-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InstructionsComponent } from './instructions/instructions.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JsonFormComponent } from './shared/components/json-form/json-form.component';
import { PhoneMaskDirective } from './shared/directives/phone-mask.directive';
import { MemberDetailsComponent } from './member-details/member-details.component';
import { ProviderDetailsComponent } from './provider-details/provider-details.component';
import {QuestionsDetailsComponent}from './questions-details/questions-details.component';
import { ServiceLocationComponent } from './service-location/service-location.component';
import { ParcticipantValidationComponent } from './parcticipant-validation/parcticipant-validation.component';
import { ParcticipantDetailsComponent } from './parcticipant-details/parcticipant-details.component';
import { SummaryDetailsComponent } from './summary-details/summary-details.component';
import { AgreementDetailsComponent } from './agreement-details/agreement-details.component';
import { ThanksCardComponent } from './thanks-card/thanks-card.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { ReqInterceptor } from './security/interceptors/req.interceptor';
import { EmptyComponent } from './empty/empty.component';
import { NgxSpinnerModule} from 'ngx-spinner';
import { NumberOnlyDirective } from './shared/directives/number-only.directive';
import { ZipDirective } from './shared/directives/zip.directive';
import { CurrencyDirective } from './shared/directives/currency.directive'
import { CurrencyPipe } from '@angular/common';
import { ReCaptchaComponent } from './shared/components/re-captcha/re-captcha.component';
import { ErrorHandlerWrapper } from './shared/error-handler/error-handler.wrapper';
import { ErrorNotificationComponent } from './shared/error-handler/error-notification.component';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UserDetailsComponent,
    ReferalDetailsComponent,
    WitnessDetailsComponent,
    InstructionsComponent,
    JsonFormComponent,
    PhoneMaskDirective,
    MemberDetailsComponent,
    ProviderDetailsComponent,
    ServiceLocationComponent,
    ParcticipantValidationComponent,
    ParcticipantDetailsComponent,
    SummaryDetailsComponent,
    AgreementDetailsComponent,
    ThanksCardComponent,
    QuestionsDetailsComponent,
    EmptyComponent,
    NumberOnlyDirective,
    ZipDirective,
    CurrencyDirective,
    ReCaptchaComponent,
    ErrorNotificationComponent 
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RecaptchaModule,
    NgxSpinnerModule,
    BrowserAnimationsModule
    ,CalendarModule
  ],
  exports: [CalendarModule],
  providers: [CurrencyPipe,
    ErrorHandlerWrapper,
    BsModalRef,
    { provide: HTTP_INTERCEPTORS, useClass: ReqInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
