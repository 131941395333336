<div class="container" role="main">
    <form class="questionsForm" [formGroup]="questionsForm">
        <div class="row main-home">
            <div class="col-md-12">
                <button type="button" class="btn btn-outline-dark" (click)="back()"
                    style="font-weight : bold;">Back</button>
            </div>
        </div>
        <div class="row main-home py-0">
            <div class="col-md-12">
                <p role="heading" aria-level="1">Please respond to all questions and enter any requested information below:</p>
            </div>
        </div>
        <div class="row" role="form">
            <div class="col-md-10" align="center">
                <app-json-form
                    *ngIf="questionsFormData && questionsFormData.controls && questionsFormData.controls.length > 0"
                    [controlsData]="questionsFormData.controls" [formData]="questionsForm"
                    (valueChangeEvent)="valueChangeEvent($event)"></app-json-form>
                <app-agreement-details [showCaptcha]="!questionsForm.invalid"></app-agreement-details>
            </div>
        </div>
    </form>
</div>