import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { Observable } from 'rxjs';
import * as formdatajson from '../../assets/data/formData.json';

@Injectable({
  providedIn: 'root'
})
export class ReferalFormService {

  formData: any;

  constructor(private http: HttpClient) { }

  public getFormLoadJSON(): any {
    let formdata = Object.assign({}, formdatajson as any);
    let result = JSON.parse(JSON.stringify(formdata));
    return result;
  }
}
