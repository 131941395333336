import { OnInit, Component } from "@angular/core";

import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'error-notification',
    templateUrl: './error-notification.component.html',
  })
export class ErrorNotificationComponent implements OnInit {
    statusCode: any;
    url: any;
    timestamp : any;
    message : string | undefined;

    ngOnInit(): void {
        this.message = this.getErrorMessage(this.statusCode);
        var apiUrl = this.url.substring(this.url.lastIndexOf('/') + 1);
        this.url = apiUrl.includes('?') ? apiUrl.substring(0, apiUrl.lastIndexOf('?')) : apiUrl;
        var zone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
        this.timestamp = new Date().toLocaleString() + ' ' + zone;
    }  
    
    getErrorMessage(code: any) {
       
        var display = "HTTP Error";
        switch (code) {
            case 400:
                display = 'Bad Request';
                break;
            case 401:
                display = 'Unauthorized';
                break;
            case 402:
                display = 'Payment Required';
                break;
            case 403:
                display = 'Forbidden';
                break;
            case 404:
                display = 'Not Found';
                break;
            case 405:
                display = 'Method Not Allowed';
                break;
            case 406:
                display = 'Not Acceptable';
                break;
            case 407:
                display = 'Proxy Authentication Required';
                break;
            case 408:
                display = 'Request Timeout';
                break;
            case 409:
                display = 'Conflict';
                break;
            case 410:
                display = 'Gone';
                break;
            case 411:
                display = 'Length Required';
                break;
            case 412:
                display = 'Precondition Failed';
                break;
            case 413:
                display = 'Payload Too Large';
                break;
            case 414:
                display = 'Request-URI Too Long';
                break;
            case 415:
                display = 'Unsupported Media Type';
                break;
            case 416:
                display = 'Requested Range Not Satisfiable';
                break;
            case 417:
                display = 'Expectation Failed';
                break;
            case 418:
                display = 'I am a teapot';
                break;
            case 421:
                display = 'Misdirected Request';
                break;
            case 422:
                display = 'Unprocessable Entity';
                break;
            case 423:
                display = 'Locked';
                break;
            case 424:
                display = 'Failed Dependency';
                break;
            case 426:
                display = 'Upgrade Required';
                break;
            case 428:
                display = 'Precondition Required';
                break;
            case 429:
                display = 'Too Many Requests';
                break;
            case 431:
                display = 'Request Header Fields Too Large';
                break;
            case 444:
                display = 'Connection Closed Without Response';
                break;
            case 451:
                display = 'Unavailable For Legal Reasons';
                break;
            case 499:
                display = 'Client Closed Request';
                break;
            case 500:
                display = 'Internal Server Error';
                break;
            case 501:
                display = 'Not Implemented';
                break;
            case 502:
                display = 'Bad Gateway';
                break;
            case 503:
                display = 'Service Unavailable';
                break;
            case 504:
                display = 'Gateway Timeout';
                break;
            case 505:
                display = 'HTTP Version Not Supported';
                break;
            case 506:
                display = 'Variant Also Negotiates';
                break;
            case 507:
                display = 'Insufficient Storage';
                break;
            case 508:
                display = 'Loop Detected';
                break;
            case 510:
                display = 'Not Extended';
                break;
            case 511:
                display = 'Network Authentication Required';
                break;
            case 599:
                display = 'Network Connect Timeout Error';
                break;

        }
        return display;
    }

    ok() {
        this.modalRef.hide();
    }

    constructor(private modalRef:BsModalRef) {
    }
}