import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StateNames } from '../models/vendor.model';
import { VendorService } from '../shared/vendor.service';

@Component({
  selector: 'app-witness-details',
  templateUrl: './witness-details.component.html',
  styleUrls: ['./witness-details.component.css','../shared/css/common.css']
})
export class WitnessDetailsComponent implements OnInit {
  isformloaded: boolean = false;
  statelist: StateNames[] = [];

  constructor(
    private witnessFormBuilder: FormBuilder, 
    private router: Router,
    private vendorService: VendorService,
    private cdref: ChangeDetectorRef) { 
      this.formdata = JSON.parse(sessionStorage.getItem("activeformdata")??"");
      this.witnessFormData = this.formdata.witnessDetails;   
      this.payorkey = parseInt(sessionStorage.getItem("payorkey")?? "0"); 
    }

  witnessForm = this.witnessFormBuilder.group({});
  witnessFormData: any;
  payorkey : number;
  formdata : any | null;
  ngOnInit(): void {    
  
    if(this.formdata) {
      if(!this.witnessFormData.controls.find((x: { name: string; }) => x.name == "referalType").value){
       this.vendorService.getReferralTypesLookup(this.payorkey).subscribe((result)=>{
        
         this.witnessFormData.controls.find((x: { name: string; }) => x.name == "referalType").options = result.map(x => {return { "value" : x.Id, "key" : x.Title}});
       })
      }

       this.vendorService.getStateNames().subscribe((result)=>{
        
        this.statelist = result;
        this.witnessFormData.controls.find((x: { name: string; }) => x.name == "state").options = result.map(x => {return { "value" : x.Abbreviation, "key" : x.Name}});
      })
      if(!this.witnessFormData.controls.find((x: { name: string; }) => x.name == "involvedType").value){
        this.vendorService.getInvolvedPartiesLookup(this.payorkey).subscribe((result)=>{
            
          this.witnessFormData.controls.find((x: { name: string; }) => x.name == "involvedType").options = result.map(x => {return { "value" : x.Id, "key" : x.Title}});
        })
      }
      
       this.vendorService.getRequiredFields(this.payorkey).subscribe((result)=>{      
        this.witnessFormData.controls.find((x: { name: string; }) => x.name == "trackingNumber").validators.required = result.ReferralInformationCaseReferenceTrackingNumber;
        this.witnessFormData.controls.find((x: { name: string; }) => x.name == "estimatedAmount").validators.required = result.ReferralInformationEstimatedMoneyInvolved;
        this.witnessFormData.controls.find((x: { name: string; }) => x.name == "originalDetectionDt").validators.required = result.ReferralInformationOriginalDetectionDate;
        this.witnessFormData.controls.find((x: { name: string; }) => x.name == "incidentStartDt").validators.required = result.ReferralInformationStartDate;
        this.witnessFormData.controls.find((x: { name: string; }) => x.name == "incidentEndDt").validators.required = result.ReferralInformationEndDate;
        this.witnessFormData.controls.find((x: { name: string; }) => x.name == "state").validators.required = result.ReferralInformationStateIncidentOccurred;
        this.witnessFormData.controls.find((x: { name: string; }) => x.name == "county").validators.required = result.ReferralInformationCountyIncidentOccurred;    
        this.witnessFormData.controls.find((x: { name: string; }) => x.name == "county").disabled = true;    

        this.isformloaded = true;
      });
      this.cdref.detectChanges();
    }
  }
  valueChangeEvent(e : any){
    if(e.name == 'state') {
      this.vendorService.getStateCounties(this.statelist.find( y => y.Id == e.value.target.selectedIndex)?.Abbreviation?? "").subscribe((result)=>{
        this.witnessFormData.controls.find((x: { name: string; }) => x.name == "county").options = result.map(x => {return { "value" : x.County, "key" : x.County}});
      })
      this.witnessFormData.controls.find((x: { name: string; }) => x.name == "county").disabled = false;
    }
    if(e.name == 'estimatedAmount') {
      this.witnessFormData.controls.find((x: { name: string; }) => x.name == e.name).value =e.value;
    }
  }
  userDetailsSubmit() {
    if(this.witnessForm.valid){
      this.formdata.witnessDetails = this.witnessFormData; 
      sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));
      this.router.navigate(['referal']);
    }
  }

  back() {
    this.formdata.witnessDetails = this.witnessFormData; 
    sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));
    this.router.navigate([sessionStorage.getItem("securityCode")]);
  }

}
