import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ReferalFormService } from '../shared/referal-form.service';
import { VendorService } from '../shared/vendor.service';

@Component({
  selector: 'app-member-details',
  templateUrl: './member-details.component.html',
  styleUrls: ['./member-details.component.css','../shared/css/common.css']
})
export class MemberDetailsComponent implements OnInit {

  memberForm = this.memberFormBuilder.group({});
  memberFormData: any;
  memberText = "member";
  formdata : any | null;
  isformloaded: boolean = false;
  constructor(
    private memberFormBuilder: FormBuilder, 
    private router: Router,
    private vendorService: VendorService,
    private cdref: ChangeDetectorRef
  ) { 
    this.formdata = JSON.parse(sessionStorage.getItem("activeformdata")??"{}");
  this.memberFormData = this.formdata.memberDetails; 
  this.payorkey = parseInt(sessionStorage.getItem("payorkey")?? "0");  
  }
  payorkey : number;
  ngOnInit(): void {
    this.vendorService.getStateNames().subscribe((result)=>{
        
      this.memberFormData.controls.find((x: { name: string; }) => x.name == "mState").options = result.map(x => {return { "value" : x.Abbreviation, "key" : x.Name}});
    })
    this.vendorService.getRequiredFields(this.payorkey).subscribe((result)=>{      
      this.memberFormData.controls.find((x: { name: string; }) => x.name == "mFirstName").validators.required = result.MemberFirstName;
      this.memberFormData.controls.find((x: { name: string; }) => x.name == "mLastName").validators.required = result.MemberLastName;
      this.memberFormData.controls.find((x: { name: string; }) => x.name == "mID").validators.required = result.MemberID;
      this.memberFormData.controls.find((x: { name: string; }) => x.name == "mDOB").validators.required = result.MemberDOB;
      this.memberFormData.controls.find((x: { name: string; }) => x.name == "mProgram").validators.required = result.MemberPlanType;
      this.memberFormData.controls.find((x: { name: string; }) => x.name == "mAddress1").validators.required = result.MemberAddress;
      this.memberFormData.controls.find((x: { name: string; }) => x.name == "mAddress2").validators.required = result.MemberAddress;
      
      this.memberFormData.controls.find((x: { name: string; }) => x.name == "mCity").validators.required = result.MemberCity;
      this.memberFormData.controls.find((x: { name: string; }) => x.name == "mState").validators.required = result.MemberState;
      this.memberFormData.controls.find((x: { name: string; }) => x.name == "mZip").validators.required = result.MemberZIP;
      this.memberFormData.controls.find((x: { name: string; }) => x.name == "mPhoneNumber").validators.required = result.MemberPhoneNumber;        
      this.memberFormData.controls.find((x: { name: string; }) => x.name == "mEmail").validators.required = result.MemberEmail;        
      this.vendorService.getLeadSetup(this.payorkey).subscribe((leadsetupresult)=>{   
        this.memberText = leadsetupresult.PatientDescription;
        this.memberFormData.controls.find((x: { name: string; }) => x.name == "mFirstName").label = leadsetupresult.PatientDescription + " First Name";
      this.memberFormData.controls.find((x: { name: string; }) => x.name == "mLastName").label = leadsetupresult.PatientDescription + " Last Name";
      this.memberFormData.controls.find((x: { name: string; }) => x.name == "mID").label = leadsetupresult.PatientDescription + " ID";
      
        this.isformloaded = true;
      }); 
      
    }); 
  }

  memberDetailsSubmit() {
    if (this.memberForm.valid) {
      this.formdata.memberDetails = this.memberFormData;
      sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));
      this.router.navigate(['summary']);
    }
  }

  back() {
    this.formdata.memberDetails = this.memberFormData; 
    sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));
    this.router.navigate(['referal']);
  }

  valueChangeEvent(data: any) {
    if(data.name == 'mZip') {
      this.memberFormData.controls.find((x: { name: string; }) => x.name == data.name).value =data.value;
    }
  }

}
