import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appPhoneMask]'
})
export class PhoneMaskDirective {

  @Output() ngModelChange:EventEmitter<any> = new EventEmitter()
value: any

  constructor() { }

  @HostListener('input', ['$event'])
  onFocusOut(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    // let trimmed = input.value.replace(/[^0-9]*/g, "");

    // if (trimmed.length > 12) {
    //   return;
    //   trimmed = trimmed.substr(0, 12);
    // }

    // trimmed = trimmed.replace(/-/g, "");

    let numbers = [];
    // numbers.push(trimmed.substr(0, 3));
    // if (trimmed.substr(3, 3) !== "") numbers.push(trimmed.substr(3, 3));
    // if (trimmed.substr(6, 4) != "") numbers.push(trimmed.substr(6, 4));
    // input.value = numbers.join("-");
    // this.ngModelChange.emit(input.value);
  }

}
