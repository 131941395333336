import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InstructionsComponent } from './instructions/instructions.component';
import { VendorService } from './shared/vendor.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'FCReferrals';
  payorkey : number = 0;
  headerHtml! : string;
  securityCode: string | null ="";
  
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private vendorService: VendorService
    ) {    
      
    }

    ngOnInit(): void {
          
    }
  openInstruction() {
    this.modalService.open(InstructionsComponent);
  }

  setHeader(){
    this.securityCode = this.router.url.replace("/","");
    if(!(['final', 'agreement', 'summary', 'particpant', 'validateparticipant', 'service','questions', 'provider','member','referal','witness'].includes(this.securityCode.toLowerCase()))){
    
      this.vendorService.getPayorIdByCode(this.securityCode).subscribe((result) => {
        if (result != null) {
          this.payorkey = result;
          sessionStorage.setItem("payorkey", this.payorkey.toString());
          this.vendorService.getLeadHeaders(this.payorkey).subscribe((result)=>{
            let headerHtmlText =  result.HeaderText;
            headerHtmlText = headerHtmlText.replace('<img ','<img title="Header Image" alt="Header Image" ')
            this.headerHtml = headerHtmlText;
          })
        }
      })
    }else if(parseInt(sessionStorage.getItem("payorkey")?? "0")){
      this.vendorService.getLeadHeaders(parseInt(sessionStorage.getItem("payorkey")?? "0")).subscribe((result)=>{          
        let headerHtmlText =  result.HeaderText;
        headerHtmlText = headerHtmlText.replace('<img ','<img title="Header Image" alt="Header Image" ')
        this.headerHtml = headerHtmlText;
      })
    }
  }
}
