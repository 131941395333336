<div class="container" role="main">
    <form class="serviceForm" [formGroup]="serviceForm" (ngSubmit)="serviceDetailsSubmit()">
        <div class="row main-home">
            <div class="col-md-12">
                <button type="button" class="btn btn-outline-dark" (click)="back()"
                    style="font-weight : bold;">Back</button>
            </div>
        </div>
        <div class="row main-home py-0">
            <div class="col-md-12">
                <p role="heading" aria-level="1">Please enter information regarding the location of the {{title}}:</p>
            </div>
        </div>
        <div class="row" role="form">
            <div class="col-md-10" align="center">
                <app-json-form *ngIf="isformloaded" [controlsData]="serviceFormData.controls" [formData]="serviceForm"
                    (valueChangeEvent)="valueChange($event)"></app-json-form>
            </div>
            <div class="col-md-10 text-end">
                <button type="submit"
                    [ngClass]="serviceForm.valid ? 'btn btn-primary' : 'btn btn-light disabled'">Next</button>
            </div>
        </div>
    </form>
</div>
