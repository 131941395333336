import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReferalFormService } from '../shared/referal-form.service';
import { VendorService } from '../shared/vendor.service';

@Component({
  selector: 'app-provider-details',
  templateUrl: './provider-details.component.html',
  styleUrls: ['./provider-details.component.css','../shared/css/common.css']
})
export class ProviderDetailsComponent implements OnInit {

  providerForm = this.providerFormBuilder.group({});
  providerFormData: any;
  hasProviderRequired: boolean = false;
  formdata : any | null;
  payorkey: number;
  title: string = "Provider";
  isformloaded: boolean = false;
  constructor(
    private providerFormBuilder: FormBuilder, 
    private router: Router,
    private vendorService: VendorService,
    private cdref: ChangeDetectorRef
  ) {  this.formdata = JSON.parse(sessionStorage.getItem("activeformdata")??"{}");
  this.providerFormData = this.formdata.providerDetails;   
  this.payorkey = parseInt(sessionStorage.getItem("payorkey")?? "0");  
  }

  ngOnInit(): void {
    if(this.formdata.witnessDetails.controls.find((x: { name: string; }) => x.name == "involvedType").value){
      this.title = this.formdata.witnessDetails.controls.find((x: { name: string; }) => x.name == "involvedType").options.find(
        (y: { value: string; })  => 
        y.value == this.formdata.witnessDetails.controls.find((x: { name: string; }) => x.name == "involvedType").value).key;
      
    }
    this.vendorService.getLookUpProviderSpeciality(this.payorkey).subscribe((result)=>{
        
      this.providerFormData.controls.find((x: { name: string; }) => x.name == "pSpecialty").options = result.map(x => {return { "value" : x.IdentifierCode, "key" : x.Description}});
    })
    this.vendorService.getLookUpProviderType(this.payorkey).subscribe((result)=>{
        
      this.providerFormData.controls.find((x: { name: string; }) => x.name == "pType").options = result.map(x => {return { "value" : x.IdentifierCode, "key" : x.Description}});
    })
    this.vendorService.getRequiredFields(this.payorkey).subscribe((result)=>{      
      this.hasProviderRequired = result.ProviderName;
      this.providerFormData.controls.find((x: { name: string; }) => x.name == "pFirstName").validators.required = result.ProviderName;
      this.providerFormData.controls.find((x: { name: string; }) => x.name == "pLastName").validators.required = result.ProviderName;
      this.providerFormData.controls.find((x: { name: string; }) => x.name == "pOrgName").validators.required = result.ProviderName;
      this.providerFormData.controls.find((x: { name: string; }) => x.name == "pNPInumber").validators.required = result.ProviderIdentifierNumber;
      this.providerFormData.controls.find((x: { name: string; }) => x.name == "pProviderID").validators.required = result.ProviderID;
      this.providerFormData.controls.find((x: { name: string; }) => x.name == "pTIN").validators.required = result.ProviderTaxID;
      this.providerFormData.controls.find((x: { name: string; }) => x.name == "pLicenseNo").validators.required = result.ProviderLicenseNumber;
      
      this.providerFormData.controls.find((x: { name: string; }) => x.name == "pType").validators.required = result.ProviderType;
      this.providerFormData.controls.find((x: { name: string; }) => x.name == "pSpecialty").validators.required = result.ProviderSpecialty;
      this.providerFormData.controls.find((x: { name: string; }) => x.name == "pDEA").validators.required = result.ProviderDEANumber;
      this.providerFormData.controls.find((x: { name: string; }) => x.name == "pWebsite").validators.required = result.ProviderWebsite;        
      this.vendorService.getLeadSetup(this.payorkey).subscribe((leadsetupresult)=>{      
        this.providerFormData.controls.find((x: { name: string; }) => x.name == "pProviderID").helperText = ("Required Format: "+ leadsetupresult.ProviderIDText);
        this.isformloaded = true;
      });       
    }); 
  }

  providerDetailsSubmit() {
    if (this.providerForm.valid) {
      this.formdata.providerDetails = this.providerFormData;
      sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));
      this.router.navigate(['service']);
    }
  }

  back() {
    this.formdata.providerDetails = this.providerFormData; 
    sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));
    this.router.navigate(['referal']);
  }

  valueChange(data: any) {
    if(this.hasProviderRequired) {
    let firstnameVal = this.providerFormData.controls.find((x: any)=>x.name==='pFirstName').value;
    let lastnameVal = this.providerFormData.controls.find((x: any)=>x.name==='pLastName').value;
    let orgnameVal = this.providerFormData.controls.find((x: any)=>x.name==='pOrgName').value;
    if(firstnameVal && firstnameVal !== '' && lastnameVal && lastnameVal !== '') {
      this.providerForm.get('pOrgName')?.clearValidators();
      this.providerForm.get('pOrgName')?.updateValueAndValidity();
      this.providerFormData.controls.find((x: { name: string; }) => x.name == "pOrgName").validators.required = false;
    } else {
      this.providerForm.get('pOrgName')?.setValidators([Validators.required]);
      this.providerForm.get('pOrgName')?.updateValueAndValidity();
      this.providerFormData.controls.find((x: { name: string; }) => x.name == "pOrgName").validators.required = true;
    }

    if(orgnameVal && orgnameVal !== '') {
      this.providerForm.get('pFirstName')?.clearValidators();
      this.providerForm.get('pFirstName')?.updateValueAndValidity();
      this.providerForm.get('pLastName')?.clearValidators();
      this.providerForm.get('pLastName')?.updateValueAndValidity();
      this.providerFormData.controls.find((x: { name: string; }) => x.name == "pFirstName").validators.required = false;
      this.providerFormData.controls.find((x: { name: string; }) => x.name == "pLastName").validators.required = false;      
    } else {
      this.providerForm.get('pFirstName')?.setValidators([Validators.required]);
      this.providerForm.get('pFirstName')?.updateValueAndValidity();
      this.providerForm.get('pLastName')?.setValidators([Validators.required]);
      this.providerForm.get('pLastName')?.updateValueAndValidity();
      this.providerFormData.controls.find((x: { name: string; }) => x.name == "pFirstName").validators.required = true;
      this.providerFormData.controls.find((x: { name: string; }) => x.name == "pLastName").validators.required = true; 
    }
  }

    if(data.name == 'pNPInumber' || data.name =='pTIN') {
      this.providerFormData.controls.find((x: { name: string; }) => x.name == data.name).value =data.value;
    }
  }

}
