import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { QuestionControls } from '../models/vendor.model';
import { JsonFormData } from '../shared/components/json-form/json-form.component';
import { ReferalFormService } from '../shared/referal-form.service';
import { VendorService } from '../shared/vendor.service';

@Component({
  selector: 'app-questions-details',
  templateUrl: './questions-details.component.html',
  styleUrls: ['./questions-details.component.css','../shared/css/common.css']
})
export class QuestionsDetailsComponent implements OnInit {

  public questionsForm = this.questionsFormBuilder.group({});
  questionsFormData! : JsonFormData;
  count: number = 4000;
  formdata: any;
  questionsFormDataJson: any;
  payorkey: number;
  constructor(
    private questionsFormBuilder: FormBuilder, 
    private router: Router,
    private vendorService: VendorService,
    private cdref: ChangeDetectorRef
  ) {
    this.payorkey = parseInt(sessionStorage.getItem("payorkey")?? "0"); 
    const control: any={
      "name": "",
      "label": "",
      "value": "",
      "type": "textarea",
      
      "validators": {
        "required": false,
        "maxLength": 2000
      }
    };
    this.formdata = JSON.parse(sessionStorage.getItem("activeformdata")??"{}");
   
       
   }

  ngOnInit(): void {
    if(this.formdata.questionsDetails && this.formdata.questionsDetails.controls){
      this.questionsFormData = { controls : []};
      this.questionsFormData.controls = this.formdata.questionsDetails.controls;
      this.questionsFormDataJson = [];
    }else{
      this.questionsFormDataJson = this.formdata.questionsDetails;
    }
    this.vendorService.getLeadQuestion(this.payorkey).subscribe((result)=>{
      let keys = Object.keys(result);
      
      if(!Array.isArray(this.questionsFormDataJson) || ( Array.isArray(this.formdata.questionsDetails) && this.formdata.questionsDetails.length == 0)) {     
        this.questionsFormData = { controls : []};
        for(let i=1;i<30;i++){
          let active= keys.find(x => x.toLowerCase() == ('IsQuestion'+i+'Active').toLowerCase());
          let displayunknown = keys.find(x => x.toLowerCase() == ('IsQuestion'+i+'DisplayUnknown').toLowerCase());
          let textactive = keys.find(x => x.toLowerCase() == ('IsQuestion'+i+'DisplayText').toLowerCase());
          let required = keys.find(x => x.toLowerCase() == ('IsQuestion'+i+'DisplayTextRequired').toLowerCase());
         
  
          if(active && result[active]) {
            if(displayunknown && result[displayunknown]) {
              this.questionsFormData.controls.push({
                "name": "questiondDrDown" + i,
                "label": result['Question' + i],
                "value": "",
                "type": "select",
                "options": [
                  {
                    "key": "YES",
                    "value": "YES"
                  },
                  {
                    "key": "NO",
                    "value": "NO"
                  },
                  {
                    "key": "UNKNOWN",
                    "value": "UNKNOWN"
                  }
                ],
                "validators": {
                  "required": true,
                  "maxLength": 200
                },
                placeholder: '',
                required: false
              });
            }    
            if(textactive && result[textactive]){
            this.questionsFormData.controls.push({
              "name": "questionTxt" + i,
              "label": (displayunknown && result[displayunknown])? "" : result['Question' + i],
              "value": "",
              "type": "textarea",

              "options": {
                "showCount": true,
                "count": 0
              },
              "validators": {
                "required": (required && result[required]),
                "maxLength": 2000
              },
              placeholder: '',
              required: false
            });  
          }        
          }
        }
      
    }
    })
  }

  back() {
    if(this.questionsFormData){
    this.formdata.questionsDetails = this.questionsFormData; }
    sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));
    this.router.navigate(['summary']);
  }

  valueChangeEvent(data: any) {
    if(this.questionsFormData){
      this.formdata.questionsDetails = this.questionsFormData; }
    sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));
  }

}
