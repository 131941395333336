import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ReferalFormService } from '../shared/referal-form.service';
import { VendorService } from '../shared/vendor.service';

@Component({
  selector: 'app-parcticipant-details',
  templateUrl: './parcticipant-details.component.html',
  styleUrls: ['./parcticipant-details.component.css','../shared/css/common.css']
})
export class ParcticipantDetailsComponent implements OnInit {

  participantFormData: any;
  payorkey : number;
  constructor(
    private participantFormBuilder: FormBuilder, 
    private router: Router,
    private referalService: ReferalFormService,
    private cdref: ChangeDetectorRef,
    private vendorService: VendorService
  ) {
    this.payorkey = parseInt(sessionStorage.getItem("payorkey")?? "0"); 
   }
   participantForm: FormGroup = this.participantFormBuilder.group({});
   formdata : any | null;



  ngOnInit(): void {
    this.formdata = JSON.parse(sessionStorage.getItem("activeformdata")??"{}");
    
    if(this.formdata && Object.keys(this.formdata).length == 0) {
      this.formdata = this.referalService.getFormLoadJSON();      
      this.participantFormData = this.formdata.participantDetails;
      this.cdref.detectChanges();
      sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));      
      this.loadFormInit(this.payorkey);
    } else {
      this.participantFormData = this.formdata.participantDetails
      this.loadFormInit(this.payorkey);
    }
  }


  loadFormInit(payorkey: number){
    this.vendorService.getStateNames().subscribe((result)=>{
        
      this.participantFormData.controls.find((x: { name: string; }) => x.name == "paState").options = result.map(x => {return { "value" : x.Abbreviation, "key" : x.Name}});
    })
    this.vendorService.getRequiredFields(payorkey).subscribe((result)=>{    
      this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paFirstName').validators.required =result.ProviderName;
      this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paLastName').validators.required =result.ProviderName;
      this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paAddress1').validators.required =result.ProviderAddress;
      this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paAddress2').validators.required =result.ProviderAddress;
      this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paCity').validators.required =result.ProviderCity;
      this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paState').validators.required =result.ProviderState;
      this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paZip').validators.required =result.ProviderZIP;
      this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paPhone').validators.required =result.ProviderPhoneNumber;
      this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paEmail').validators.required =result.ProviderEmailAddress;
      this.vendorService.getLeadSetup(this.payorkey).subscribe((leadsetupresult)=>{ 
        this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paFirstName').label = leadsetupresult.PatientDescription + " First Name";
        this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paLastName').label = leadsetupresult.PatientDescription + " Last Name";
        this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paID').label = leadsetupresult.PatientDescription + " ID";    
        
      }); 
      if(this.formdata.validateParticipant.subcontrols.find((x: { name: any; }) => x.name == "vpIsreferring").value == "YES"){
        this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paFirstName').value = this.formdata.extenalReferall.subcontrols.find((x: { name: string; }) => x.name == "wFirstName").value;
      this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paLastName').value =this.formdata.extenalReferall.subcontrols.find((x: { name: string; }) => x.name == "wLastName").value;
      this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paAddress1').value = this.formdata.extenalReferall.subcontrols.find((x: { name: string; }) => x.name == "wAddress1").value;
      this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paAddress2').value =this.formdata.extenalReferall.subcontrols.find((x: { name: string; }) => x.name == "wAddress2").value;
      this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paCity').value =this.formdata.extenalReferall.subcontrols.find((x: { name: string; }) => x.name == "city").value;
      this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paState').value =this.formdata.extenalReferall.subcontrols.find((x: { name: string; }) => x.name == "state").value;
      this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paZip').value =this.formdata.extenalReferall.subcontrols.find((x: { name: string; }) => x.name == "zip").value;
      this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paPhone').value =this.formdata.extenalReferall.subcontrols.find((x: { name: string; }) => x.name == "phone").value;
      this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paEmail').value =this.formdata.extenalReferall.subcontrols.find((x: { name: string; }) => x.name == "email").value;
  
      }else{
        this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paFirstName').value ='';
        this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paLastName').value ='';
        this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paAddress1').value ='';
        this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paAddress2').value ='';
        this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paCity').value ='';
        this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paState').value ='';
        this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paZip').value ='';
        this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paPhone').value ='';
        this.participantFormData.controls.find((x: { name: string; }) => x.name === 'paEmail').value ='';
    
      }
    
    })
  }
 

  participantDetailsSubmit() {  
    if (this.participantForm.valid) {
      this.formdata.participantDetails = this.participantFormData;
      sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));
      this.router.navigate(['summary']);
    }
  }

  back() {
    this.router.navigate(['validateParticipant']);
  }

  valueChange(data: any) {
    if(data.name == 'paZip') {
      this.participantFormData.controls.find((x: { name: string; }) => x.name == data.name).value =data.value;
    }
  }

}
