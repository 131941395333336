import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ReferalFormService } from '../shared/referal-form.service';
import { VendorService } from '../shared/vendor.service';

@Component({
  selector: 'app-thanks-card',
  templateUrl: './thanks-card.component.html',
  styleUrls: ['./thanks-card.component.css','../shared/css/common.css']
})
export class ThanksCardComponent implements OnInit {

  finalForm = this.finalFormBuilder.group({});
  finalFormData: any;
  formdata: any;
  payorkey: number;
  confirmationmsg = "";
  isMember: boolean = false;
  constructor(
    private finalFormBuilder: FormBuilder, 
    private router: Router,
    private finalService: ReferalFormService,
    private vendorService: VendorService
  ) { 
    this.formdata = JSON.parse(sessionStorage.getItem("activeformdata")??"");
    this.finalFormData = this.formdata.finalDetails;  
    this.payorkey = parseInt(sessionStorage.getItem("payorkey")?? "0");   
  }

  ngOnInit(): void {
    this.loadDate();
  }

  loadDate(){
    this.vendorService.getLeadConfirmation(this.payorkey).subscribe((result)=>{
      this.confirmationmsg = result.Message;
      localStorage.removeItem("activeformdata");
      sessionStorage.removeItem("activeformdata");
    })

   
  }

  finalDetailsSubmit() {    
    window.close();
    this.router.navigateByUrl("/" + (sessionStorage.getItem("securityCode") ?? ""));
   
  }

}
