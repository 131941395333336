import { Injectable, Renderer2 } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ErrorNotificationComponent } from "./error-notification.component";

@Injectable()
export class ErrorHandlerWrapper {
    modalRef: BsModalRef | undefined;

    handleError(error: any) {
        const initialState = { "statusCode": error.status, "url": error.url };
        
        this.modalRef = this.modalService.show(ErrorNotificationComponent, 
                            {
                                animated: false,
                                initialState: initialState
                            });
    }

    constructor(private modalService: BsModalService) {        
        
        
    }
}