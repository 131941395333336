<div class="container">
    <ngx-spinner type="ball-scale-multiple"></ngx-spinner><ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>
    <form class="agreementForm" [formGroup]="agreementForm" (ngSubmit)="agreementDetailsSubmit()">
    <div class="row">
        <div>
            <app-re-captcha (captchaEvent)="captchaEvent($event)"></app-re-captcha>
        </div>
        <div class="col-md-2">            
            <button type="submit" [ngClass]="(!showCaptcha || !captcha) ? 'btn btn-light disabled' : 'btn btn-primary' "    
            >Submit Referal</button>
        </div>
    </div>
    </form>
    </div>


