
<form [formGroup]="formData" class="text-start">
    <div class="mb-3 row" *ngFor="let item of controlsData">
      <div class="col-sm-5 col-form-label">
    <label [for]="item.name" class="col-sm-12" *ngIf="item.label" [innerHtml]="item.label"></label>
    <label [for]="item.name" class="col-sm-12" *ngIf="!item.label">Enter the text</label>
    
    <span class="col-sm-2 fs-14" *ngIf="item.validators.required">(Required)</span>
    <span class="col-sm-2" *ngIf="!item.validators.required">&nbsp;</span>
  </div>
    <div class="col-sm-5">
      <input *ngIf="
      [
        'text',
        'password',
        'email',
        'search',
        'url'
      ].includes(item.type)
    "
    [placeholder]="item.placeholder?item.placeholder:''"
    [type]="item.type" 
    [formControlName]="item.name" 
    class="form-control" 
    [id]="item.name" 
    [name]="item.name"
    [value]="item.value"
    [(ngModel)]="item.value"
    (change)="modelChangeEvent($event, item)"
    />

    <input *ngIf="item.type==='number'"
    [placeholder]="item.placeholder?item.placeholder:''"
    type="text" 
    appNumberOnly
    [formControlName]="item.name" 
    class="form-control" 
    [id]="item.name" 
    [name]="item.name"
    [value]="item.value"
    [ngModel]="item.value"
    [minlength]="item?.options?.minLength ?? 0"
    [maxlength]="item?.options?.maxLength ?? 250"
    pattern="[0-9]*"
    (ngModelChange)="modelChangeEvent($event, item)"
    />

    <input *ngIf="item.type==='currency'"
    [placeholder]="item.placeholder?item.placeholder:''"
    type="text" 
    appCurrency
    maxDigits="9"
    pattern="^(?![0,.]+$)(?:0|[1-9]\d{0,2}(?:,\d{3})*|[1-9]\d*)(?:\.\d{1,2})?$"
    [formControlName]="item.name" 
    class="form-control" 
    [id]="item.name" 
    [name]="item.name"
    [value]="item.value"
    [ngModel]="item.value"
    [minlength]="item?.options?.minLength ?? 0"
    [maxlength]="item?.options?.maxLength ?? 250"
    (ngModelChange)="modelChangeEvent($event, item)"
    />

    <input *ngIf="item.type==='zip'"
    [placeholder]="item.placeholder?item.placeholder:''"
    type="text" 
    appZip
    [formControlName]="item.name" 
    class="form-control" 
    [id]="item.name" 
    [name]="item.name"
    [value]="item.value"
    [ngModel]="item.value"
    (ngModelChange)="modelChangeEvent($event, item)"
    />
    <span *ngIf="item.type==='date'" class="dateFieldOnlineReferrals" >
    <p-calendar 
    placeholder="MM/DD/YYYY"
    [formControlName]="item.name" 
    [id]="item.name" 
    [name]="item.name"
    [(ngModel)]="item.value"
    [minDate]="item.options.min"
    [maxDate]="item.options.max"
    (input)="formatDateToMMDDYYYY($event, item);onDateInput($event)"
    (onClearClick)="formatDateToMMDDYYYY($event, item)"
    (onSelect)="formatDateToMMDDYYYY($event, item)"
    [showButtonBar]="true" [showIcon]="true" (click)="onCalendarClick()"
    ></p-calendar></span>

    <input *ngIf="item.type==='phone'" 
    type="tel" 
    [formControlName]="item.name"  
    class="form-control" 
    [id]="item.name" 
    [name]="item.name"
    [value]="item.value"
    [ngModel]="item.value"
    (ngModelChange)="modelChangeEvent($event, item)"
    
     />

    <select *ngIf="item.type==='select'"
     class="form-select" 
     [formControlName]="item.name"  
     aria-label="Default select example" 
     [id]="item.name" 
     [(ngModel)]="item.value"
     (change)="modelChangeEvent($event, item)"
     [name]="item.name" >  
     <option value="" disabled selected>Select an option</option>                 
        <option [value]="option.value" *ngFor="let option of item.options">{{option.key}}</option>
      </select>

      <textarea 
      *ngIf="item.type==='textarea'"
      class="form-control" 
      [placeholder]="item.placeholder?item.placeholder:''"
      [formControlName]="item.name" 
      [id]="item.name" 
      [name]="item.name" 
      [(ngModel)]="item.value"
      (ngModelChange)="modelChangeEvent($event, item)"
      [maxlength]="item.validators.maxLength?item.validators.maxLength:null"
      cols="30" 
      rows="3"></textarea>
      <span class="fs-14" *ngIf="item.type==='textarea'&&item?.options.showCount">Character space remaining: {{item.options.count}}</span>
    </div>
    <div class="col-sm-2">
      <span class="fs-14" *ngIf="item.helperText">{{item.helperText}}</span>
    </div>
</div>
</form>
