<div class="container" role="main">
    <form class="userForm" [formGroup]="userForm" (ngSubmit)="userDetailsSubmit()">
        <div class="row main-home">
            <div class="col-md-12">
                <button type="button" class="btn btn-outline-dark" *ngIf="isInstruction" (click)="openInstruction()"
                    style="font-weight : bold;">Instructions</button>
            </div>
        </div>
        <div class="row main-home py-0">
            <div class="col-md-12">
                <p role="heading" aria-level="1">Please enter your contact information. We may need to contact you for additional information or
                    clarification:</p>
            </div>
        </div>
        <div class="row" role="form">
            <div class="col-md-10" align="center" *ngIf="isformloaded">
                <app-json-form [controlsData]="userFormData.controls" [formData]="userForm"
                    (valueChangeEvent)="valueChangeEvent($event)"></app-json-form>
            </div>
            <div class="col-md-10 text-end">
                <button type="submit" [ngClass]="userForm.valid ? 'btn btn-primary' : 'btn btn-light disabled'">Next</button>
            </div>
        </div>
    </form>
</div>

<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium"
    color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner> 
