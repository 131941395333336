import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ReferalFormService } from '../shared/referal-form.service';
import { VendorService } from '../shared/vendor.service';

@Component({
  selector: 'app-summary-details',
  templateUrl: './summary-details.component.html',
  styleUrls: ['./summary-details.component.css','../shared/css/common.css']
})
export class SummaryDetailsComponent implements OnInit {

  summaryForm = this.summaryFormBuilder.group({});
  summaryFormData: any;
  count: number = 4000;
  formdata : any | null;
  constructor(
    private summaryFormBuilder: FormBuilder, 
    private router: Router,
    
    private vendorService: VendorService,
    private cdref: ChangeDetectorRef
  ) {
    this.formdata = JSON.parse(sessionStorage.getItem("activeformdata")??"{}");
  this.summaryFormData = this.formdata.summaryDetails; 
  this.payorkey = parseInt(sessionStorage.getItem("payorkey")?? "0"); 
   }
   isMember = false;
   payorkey : number;
  ngOnInit(): void {
    this.loadPartiesInvolved();
  }
  loadPartiesInvolved(){
    this.vendorService.getInvolvedPartiesLookup(this.payorkey).subscribe((result)=>{
          
      let selectedpartyId = this.formdata.witnessDetails.controls.find((x: { name: string; }) => x.name == "involvedType").value;
      let selectedparty = result.find(x => x.Id == selectedpartyId);
      if(selectedparty){
        this.isMember = selectedparty.IsMember || !selectedparty.IsProvider;
      }
    })
  }

  summaryDetailsSubmit() {
    if (this.summaryForm.valid) {
    this.formdata.summaryDetails = this.summaryFormData; 
    sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));
    this.router.navigate(['questions']);
    }
  }

  back() {
    this.formdata.summaryDetails = this.summaryFormData; 
    sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));
    if(this.isMember){
      this.router.navigate(['member']);
    }else{
      this.router.navigate(['provider']);
    }
  }

  valueChangeEvent(data: any) {
  }

}
