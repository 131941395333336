<div class="container" role="main">
    <form class="validateParticipantForm" [formGroup]="validateParticipantForm" (ngSubmit)="validateParticipantDetailsSubmit()">
        <div class="row main-home">
            <div class="col-md-12">
                <button type="button" class="btn btn-outline-dark" (click)="back()" style="font-weight : bold;" >Back</button>
            </div>
        </div>
        <div class="row main-home py-0">
            <div class="col-md-12">
                <p role="heading" aria-level="1">Please enter responses to the questions below:</p>
            </div>
        </div>
        <div class="row" role="form">
            <div class="col-md-10" align="center">
                <app-json-form [controlsData]="validateParticipantFormData.controls"
                    (valueChangeEvent)="valueChangeEvent($event)" [formData]="validateParticipantForm"></app-json-form>
                <div *ngIf="validateParticipantForm.get('vpIsParticipant')?.value == 'YES'">
                    <app-json-form *ngIf="validateParticipantForm.get('vpIsParticipant')?.value == 'YES'"
                        (valueChangeEvent)="valueChangeEvent($event)" [controlsData]="validateParticipantFormData.subcontrols"
                        [formData]="validateParticipantForm"></app-json-form>
                </div>
            </div>
            <div class="col-md-10 text-end">
                <button type="submit"
                    [ngClass]="!(validateParticipantForm.invalid && validateParticipantForm.get('isExternalReferal')?.value != 'NO') ? 'btn btn-primary' : 'btn btn-light disabled'">Next</button>
            </div>
        </div>
    </form>
    
    </div>