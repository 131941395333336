<div class="row">
    <div class="col-md-11">
        <h1>
        <img class="ActivityIndicator" src="../../../assets/images/info-circle-fill.png" />
        &nbsp;
        <span style="vertical-align:5px;" class="darkNavy-fc title1">Sorry, there was a minor problem.</span>
    </h1>
</div>
    <div class="col-md-1">
        <img src="../../../assets/images/x-circle-fill.png" alt="Activity Image" style="margin-top:25px">
    </div>
</div>
<div class="modal-body" style="color:#43425d !important;margin-top:-15px">
    <div style="height:100px;">
        <span class="darkNavy-fc">
            The server/client did not respond timely. From time to time, this may occur for a single widget (or section) and you may continue to use the system. If however, data is not populating in a critical widget (or section) please try again.
        </span>
    </div>
    <div style="height:80px; border: thin solid;color:#43425d; margin-top: 5px;">
        <span style="margin-left: 10px;">{{statusCode}} - {{message}}</span><br />
        <span style="margin-left: 10px;">{{url}}</span><br />
        <span style="margin-left: 10px;">{{timestamp}}</span>
    </div>
    <div style="height:120px; margin-top: 10px;">
        <span class="darkNavy-fc">
            It is recommended that you clear your cookies and cached files for ‘All Time’ in an attempt to resolve this issue.
            <br />
            <br />
            If this error persists after you have cleared your cache and cookies, or if you believe this to be a more significant issue, please report this problem by emailing the following address: FraudCapturesupport@gainwelltechnologies.com
        </span>
    </div>
</div>
<div class="modal-footer" style="border:0px">
    <button class="orangeBtn" (click)="ok()">Close</button>
</div>
