import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgreementDetailsComponent } from './agreement-details/agreement-details.component';
import { MemberDetailsComponent } from './member-details/member-details.component';
import { ParcticipantDetailsComponent } from './parcticipant-details/parcticipant-details.component';
import { ParcticipantValidationComponent } from './parcticipant-validation/parcticipant-validation.component';
import { ProviderDetailsComponent } from './provider-details/provider-details.component';
import { ReferalDetailsComponent } from './referal-details/referal-details.component';
import { ServiceLocationComponent } from './service-location/service-location.component';
import { SummaryDetailsComponent } from './summary-details/summary-details.component';
import { ThanksCardComponent } from './thanks-card/thanks-card.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { QuestionsDetailsComponent } from './questions-details/questions-details.component';
import { WitnessDetailsComponent } from './witness-details/witness-details.component';
import { EmptyComponent } from './empty/empty.component';


const routes: Routes = [
  {
    path:'',
    component: EmptyComponent
  },
  {
    path: '*',
    component: UserDetailsComponent
  },
  {
    path:'witness',
    component: WitnessDetailsComponent
  },
  {
    path: 'referal',
    component: ReferalDetailsComponent
  },
  {
    path: 'member',
    component: MemberDetailsComponent
  },
  {
    path: 'provider',
    component: ProviderDetailsComponent
  },
  {
    path:'questions',
    component: QuestionsDetailsComponent
  },
  {
    path: 'service',
    component: ServiceLocationComponent    
  },
  {
    path:'validateParticipant',
    component: ParcticipantValidationComponent
  },
  {
    path:'particpant',
    component: ParcticipantDetailsComponent
  },
  {
    path:'summary',
    component: SummaryDetailsComponent
  },
  {
    path:'agreement',
    component: AgreementDetailsComponent
  },
  {
    path:'final',
    component: ThanksCardComponent
  },
  {
    path:'**',
    component: UserDetailsComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
