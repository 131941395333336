import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  tabIndex: number =0;

  constructor() { }

  ngOnInit(): void {
  }

  addTabCount() {
    this.tabIndex = this.tabIndex + 1;
  }

  downTabCount() {
    if(this.tabIndex == 0)
    {
      return;
    }
    this.tabIndex = this.tabIndex - 1;
  }


}
