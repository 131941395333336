<div class="container" role="main">
    <form class="summaryForm" [formGroup]="summaryForm" (ngSubmit)="summaryDetailsSubmit()">
        <div class="row main-home">
            <div class="col-md-12">
                <button type="button" class="btn btn-outline-dark" (click)="back()"
                    style="font-weight : bold;">Back</button>
            </div>
        </div>
        <div class="row main-home py-0">
            <div class="col-md-12">
                <p role="heading" aria-level="1">Please provide a summary of this referral:</p>
            </div>
        </div>
        <div class="row" role="form">
            <div class="col-md-10" align="center">
                <app-json-form [controlsData]="summaryFormData.controls" (valueChangeEvent)="valueChangeEvent($event)"
                    [formData]="summaryForm"></app-json-form>
            </div>
            <div class="col-md-10 text-end">
                <button type="submit"
                    [ngClass]="summaryForm.valid ? 'btn btn-primary' : 'btn btn-light disabled'">Next</button>
            </div>
        </div>
    </form>
</div>