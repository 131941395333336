import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';


interface JsonFormValidators {
  min?: number;
  max?: number;
  required?: boolean;
  requiredTrue?: boolean;
  email?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  nullValidator?: boolean;
}
interface JsonFormControlOptions {
  min?: string;
  max?: string;
  step?: string;
  icon?: string;
  showCount?: boolean;
  count?: number;
}
interface JsonFormControls {
  name: string;
  label: string;
  value: any;
  type: string;
  placeholder: string;
  helperText?: string;
  options?: JsonFormControlOptions | any;
  required: boolean;
  validators: JsonFormValidators;
}
export interface JsonFormData {
  controls: JsonFormControls[];
}

@Component({
  selector: 'app-json-form',
  templateUrl: './json-form.component.html',
  styleUrls: ['./json-form.component.css','../../css/common.css']
})

export class JsonFormComponent implements OnInit, OnChanges {
  @Input()
  formData: FormGroup | any;

  @Input()
  controlsData: JsonFormControls[] = [];

  @Output()
  valueChangeEvent = new EventEmitter<any>();

  constructor(private fb: FormBuilder,private elementRef: ElementRef,private appComponent:AppComponent) { }

  ngOnInit(): void {
    this.createForm(this.controlsData);
    var tempFilterData = this.controlsData;
    tempFilterData.forEach(field => {
        if(field.type==="date"){
         if(field.options.min)
         {field.options.min=new Date(field.options.min) }
         if(field.options.min){
          field.options.max=new Date(field.options.max) }
          if(field.value){
            field.value=new Date(field.value) }}});
  }

  ngOnChanges(changes: SimpleChanges) {
      
  }
  onDateInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, '');
    if (value.length >= 3 && value.length <= 4) {
      value = value.substring(0, 2)+'/'+value.substring(2);
    } else if (value.length >= 5) {
      value = value.substring(0, 2)+'/'+value.substring(2, 4)+'/'+value.substring(4); }
    input.value = value;
  }
  createForm(controls: JsonFormControls[]) {
    for (const control of controls) {
      const validatorsToAdd = [];
      for (const [key, value] of Object.entries(control.validators)) {
        switch (key) {
          case 'min':
            validatorsToAdd.push(Validators.min(value));
            break;
          case 'max':
            validatorsToAdd.push(Validators.max(value));
            break;
          case 'required':
            if (value) {
              validatorsToAdd.push(Validators.required);
            }
            break;
          case 'requiredTrue':
            if (value) {
              validatorsToAdd.push(Validators.requiredTrue);
            }
            break;
          case 'email':
            if (value) {
              validatorsToAdd.push(Validators.email);
            }
            break;
          case 'regex':
            if(value) {
              validatorsToAdd.push(Validators.pattern(value));
            }
            break;
          case 'minLength':
            validatorsToAdd.push(Validators.minLength(value));
            break;
          case 'maxLength':
            validatorsToAdd.push(Validators.maxLength(value));
            break;
          case 'pattern':
            validatorsToAdd.push(Validators.pattern(value));
            break;
          case 'nullValidator':
            if (value) {
              validatorsToAdd.push(Validators.nullValidator);
            }
            break;
          default:
            break;
        }
      }
      this.formData?.addControl(
        control.name,
        this.fb.control(control.value, validatorsToAdd)
      );
    }
  }
  ngAfterViewInit() {
    const calendarButton = this.elementRef.nativeElement.querySelector('.p-datepicker-trigger');
    if (calendarButton) {
        calendarButton.setAttribute('aria-label', 'Toggle Datepicker');
    }
}

onCalendarClick(){
    const calendarPrev = this.elementRef.nativeElement.querySelector('.p-datepicker-prev');
    const calendarNext = this.elementRef.nativeElement.querySelector('.p-datepicker-next');
    
    if (calendarPrev) {
      calendarPrev.setAttribute('aria-label', 'prevButton');}
    if (calendarNext) {
      calendarNext.setAttribute('aria-label', 'nextButton');
    }}

  formatDateToMMDDYYYY(value: any, item: any) {
    if(item.value){
    var value=item.value.toISOString().split('T')[0];
    value.toString()
    this.modelChangeEvent(value,item)}
}

  modelChangeEvent(value: any, item: any) {
    const data = {
      value: value, 
      name: item.name
    }
    if(item.type== 'textarea') {
      item.options.count = item.validators.maxLength-value.length;
    }
    this.valueChangeEvent.emit(data);
  }

}
