import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReferalFormService } from '../shared/referal-form.service';
import { VendorService } from '../shared/vendor.service';

@Component({
  selector: 'app-referal-details',
  templateUrl: './referal-details.component.html',
  styleUrls: ['./referal-details.component.css','../shared/css/common.css']
})
export class ReferalDetailsComponent implements OnInit {
  isformloaded: boolean = false;

  constructor(
    private referalFormBuilder: FormBuilder, 
    private router: Router,
    private referalService: ReferalFormService,
    private vendorService: VendorService,
    private cdref: ChangeDetectorRef) { 
      this.formdata = JSON.parse(sessionStorage.getItem("activeformdata")??"");
      this.referalFormData = this.formdata.extenalReferall;  
      this.payorkey = parseInt(sessionStorage.getItem("payorkey")?? "0");   
    }
  formdata : any | null;
  referalForm = this.referalFormBuilder.group({});
  referalFormData: any;
  payorkey : number;
  isMember = false;
  ngOnInit(): void {
    this.loadPartiesInvolved();
  }
  loadPartiesInvolved(){
    this.vendorService.getInvolvedPartiesLookup(this.payorkey).subscribe((result)=>{
          
      let selectedpartyId = this.formdata.witnessDetails.controls.find((x: { name: string; }) => x.name == "involvedType").value;
      let selectedparty = result.find(x => x.Id == selectedpartyId);
      if(selectedparty){
        this.isMember = selectedparty.IsMember || !selectedparty.IsProvider;
      }
    })
    this.vendorService.getStateNames().subscribe((result)=>{
        
      this.referalFormData.subcontrols.find((x: { name: string; }) => x.name == "state").options = result.map(x => {return { "value" : x.Abbreviation, "key" : x.Name}});
    })
    this.vendorService.getRequiredFields(this.payorkey).subscribe((result)=>{      
      this.referalFormData.subcontrols.find((x: { name: string; }) => x.name == "wFirstName").validators.required = result.WitnessFirstName;
      this.referalFormData.subcontrols.find((x: { name: string; }) => x.name == "wLastName").validators.required = result.WitnessLastName;
      this.referalFormData.subcontrols.find((x: { name: string; }) => x.name == "wAgencyName").validators.required = result.WitnessAgencyName;
      this.referalFormData.subcontrols.find((x: { name: string; }) => x.name == "wRelationShip").validators.required = result.WitnessRelationshiptoSubject;
      this.referalFormData.subcontrols.find((x: { name: string; }) => x.name == "wAddress1").validators.required = result.WitnessAddress;
      this.referalFormData.subcontrols.find((x: { name: string; }) => x.name == "wAddress2").validators.required = result.WitnessAddress;
      
      this.referalFormData.subcontrols.find((x: { name: string; }) => x.name == "city").validators.required = result.WitnessCity;
      this.referalFormData.subcontrols.find((x: { name: string; }) => x.name == "state").validators.required = result.WitnessState;
      this.referalFormData.subcontrols.find((x: { name: string; }) => x.name == "zip").validators.required = result.WitnessZIP;
      this.referalFormData.subcontrols.find((x: { name: string; }) => x.name == "phone").validators.required = result.WitnessPhoneNumber;        
      this.referalFormData.subcontrols.find((x: { name: string; }) => x.name == "email").validators.required = result.WitnessEmail;        

      this.isformloaded = true;
    });
  }
  referalFormSubmit() {
    if (this.referalForm.valid) {
      this.formdata.extenalReferall = this.referalFormData;
      sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));
      if(this.isMember){
        this.router.navigate(['member']);
      }else{
        this.router.navigate(['provider']);
      }
    }
    // if(this.formdata.witnessDetails.controls.find((x: any)=>x.name==='involvedType').value === 'Participant'){
    //   this.router.navigate(['validateParticipant']);
    // }
  }

  back() {
    this.formdata.extenalReferall = this.referalFormData; 
    sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));
    this.router.navigate(['witness']);
  }

  valueChange(data: any) {
    if(data.name == 'zip') {
      this.referalFormData.subcontrols.find((x: { name: string; }) => x.name == data.name).value =data.value;
    }
  }

}
