
    <div class="container-fluid">
      <div class="row" role="banner" aria-label="1">
        <div class="text-center" [innerHtml]="headerHtml">
        </div>
      </div>
      
        <router-outlet (activate)="setHeader()"></router-outlet>
     
    </div>
  

