<div class="container" role="main">
    <form class="finalForm" [formGroup]="finalForm" (ngSubmit)="finalDetailsSubmit()">
        <div class="row">
            <div class="col-md-10" [innerHtml]="confirmationmsg">
            </div>
            <p role="heading" aria-level="1">
                You may close this browser or hit the 'Enter New Referral' button to return to the form
            </p>
            <div class="col-md-10">
                <button type="submit" class="btn btn-primary" [disabled]="finalForm.invalid">Enter New Referral</button>
            </div>
        </div>
    </form>

</div>