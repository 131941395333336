<div class="container" role="main">

    <form class="witnessForm" [formGroup]="witnessForm" (ngSubmit)="userDetailsSubmit()">
        <div class="row main-home">
            <div class="col-md-12">
                <button type="button" class="btn btn-outline-dark" (click)="back()" style="font-weight : bold;">Back</button>
            </div>
        </div>
        <div class="row main-home py-0">
            <div class="col-md-12">
                <p role="heading" aria-level="1">Please enter information regarding this referral:</p>
            </div>
        </div>
        <div class="row" role="form">
            <div class="col-md-10" align="center">
                <app-json-form *ngIf="isformloaded" [controlsData]="witnessFormData.controls" [formData]="witnessForm"
                    (valueChangeEvent)="valueChangeEvent($event)"></app-json-form>
            </div>
            <div class="col-md-10 text-end enableButtonClass">
                <button type="submit" [disabled]="!witnessForm.valid" [ngClass]="witnessForm.valid ? 'btn btn-primary' : 'btn btn-light disabled'">Next</button>
            </div>
        </div>
    </form>
    
    </div>