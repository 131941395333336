<div class="container" role="main">
    <form class="referalForm" [formGroup]="referalForm" (ngSubmit)="referalFormSubmit()">
        <div class="row main-home">
            <div class="col-md-12">
                <button type="button" class="btn btn-outline-dark" (click)="back()" style="font-weight : bold;" >Back</button>
            </div>
        </div>
        <div class="row main-home py-0">
            <div class="col-md-12">
                <p role="heading" aria-level="1">Please enter any witness or referring party information:</p>
            </div>
        </div>
        <div class="row" role="form" *ngIf="isformloaded">
            <div class="col-md-10" align="center">
                <app-json-form [controlsData]="referalFormData.controls" [formData]="referalForm"></app-json-form>
                <div *ngIf="referalForm.get('isExternalReferal')?.value == 'YES'">
                    <app-json-form [controlsData]="referalFormData.subcontrols" [formData]="referalForm"
                        (valueChangeEvent)="valueChange($event)"></app-json-form>
                    <!-- <div class="col-md-11 text-end">
                        <button type="submit" class="btn btn-primary" [disabled]="referalForm.invalid">Next</button>
                    </div> -->
                </div>
            </div>
            <div class="col-md-10 text-end">
                <button type="submit" [ngClass]="referalForm.valid ? 'btn btn-primary' : 'btn btn-light disabled'">Next</button>
            </div>
        </div>
    </form>
    
    </div>
