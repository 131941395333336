<div class="modal-header" id="Instructions">
    <h4 class="modal-title">Instructions</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body" id="MyDIv" [innerHtml]="name">
    
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="exportAsPDF('MyDIv');">Download</button>
    <button type="button" class="btn btn-outline-dark"  style="font-weight : bold;" (click)="activeModal.close('Close click')">Close</button>
  </div>