import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InstructionsComponent } from '../instructions/instructions.component';
import { ReferalFormService } from '../shared/referal-form.service';
import { VendorService } from '../shared/vendor.service';
import { NgxSpinnerService } from 'ngx-spinner'

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css','../shared/css/common.css']
})
export class UserDetailsComponent implements OnInit {

  userFormData: any;
  payorkey = 0;
  securityCode! : string | null;
  instructionText!: string | null;
  isformloaded = false;
  constructor(
    private userFormBuilder: FormBuilder, 
    private router: Router,
    private route: ActivatedRoute,
    private referalService: ReferalFormService,
    private modalService: NgbModal,
    private cdref: ChangeDetectorRef,
    private vendorService: VendorService,
    private ngxsippner : NgxSpinnerService
    ) {    
      this.securityCode = router.url.replace("/","");
      this.payorkey = parseInt(sessionStorage.getItem("payorkey")?? "0"); 
      sessionStorage.setItem("securityCode", this.securityCode);        
    }

  userForm: FormGroup = this.userFormBuilder.group({});
  isInstruction: boolean = false;
  public formdata : any | null;
  ngOnInit(): void {
    this.isformloaded = false;
    this.formdata = JSON.parse(sessionStorage.getItem("activeformdata")??"{}");
      
  if(this.formdata && Object.keys(this.formdata).length == 0) {
    this.formdata = this.referalService.getFormLoadJSON();    
      this.userFormData = this.formdata.userDetails;
      this.cdref.detectChanges();
      sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));      
      this.loadFormInit(this.payorkey);
  } 
  else {
    this.userFormData = this.formdata.userDetails
     this.loadFormInit(this.payorkey);    
  }
  }

  loadFormInit(payorkey: number){
    
    if(!payorkey){
      this.vendorService.getPayorIdByCode(this.securityCode).subscribe((result) => {
        this.payorkey = result;
        sessionStorage.setItem("payorkey", this.payorkey.toString());      
        this.loadData();
    }) 
    }else{
      this.loadData();
    }
  }
  loadData(){
    this.ngxsippner.show();
    this.vendorService.getRequiredFields(this.payorkey).subscribe((result)=>{      
      this.userFormData.controls.find((x: { name: string; }) => x.name == "firstName").validators.required = result.ReferringPartyFirstName;
      this.userFormData.controls.find((x: { name: string; }) => x.name == "lastName").validators.required = result.ReferringPartyLastName;
      this.userFormData.controls.find((x: { name: string; }) => x.name == "orgName").validators.required = result.ReferringPartyOrganizationAgencyName;
      this.userFormData.controls.find((x: { name: string; }) => x.name == "title").validators.required = result.ReferringPartyTitle;
      this.userFormData.controls.find((x: { name: string; }) => x.name == "phone").validators.required = result.ReferringPartyPhoneNumber;
      this.userFormData.controls.find((x: { name: string; }) => x.name == "email").validators.required = result.ReferringPartyEmailAddress;
      this.isformloaded = true;
    });

    this.vendorService.getReferringSourcesLookup(this.payorkey).subscribe((result)=>{          
      this.userFormData.controls.find((x: { name: string; }) => x.name == "orgName").options = result.map(x => {return { "value" : x.Id, "key" : x.Title}});
      this.ngxsippner.hide();
    }, error => {this.ngxsippner.hide()}); 
  
    this.vendorService.getLeadHeaders(this.payorkey).subscribe((result)=>{          
      this.isInstruction = result.IsInstructionEnbaled;
      let instructionText =  result.Instruction;
      instructionText = instructionText.replace('<img ','<img title="Header" ')
      this.instructionText = instructionText;
    })
  }

  userDetailsSubmit() {
    if(this.userForm.valid){
      this.formdata.userDetails = this.userFormData; 
      sessionStorage.setItem("activeformdata", JSON.stringify(this.formdata));
      this.router.navigate(['witness']);
    }
  }

  openInstruction() {
    
    const result  = this.modalService.open(InstructionsComponent, {size: 'lg',  ariaLabelledBy: 'Instructions'});
    result.componentInstance.name = this.instructionText;
  }

  valueChangeEvent(data: any) {
    // if(data.name ==='phone') {
    //   this.userFormData.controls.find((x: { name: string; }) => x.name == "phone").value =data.value;
    // }
  }

}
