<div class="container" role="main">

    <form class="participantForm" [formGroup]="participantForm" (ngSubmit)="participantDetailsSubmit()">
        <div class="row main-home">
            <div class="col-md-1">
                <button type="button" class="btn btn-outline-dark" (click)="back()" style="font-weight : bold;" >Back</button>
            </div>
        </div>
        <div class="row main-home py-0">
            <div class="col-md-12">
                <p role="heading" aria-level="1">Please enter the requested information below:</p>
            </div>
        </div>
        <div class="row" role="form">
            <div class="col-md-10" align="center">
          <app-json-form [controlsData]="participantFormData.controls" [formData]="participantForm" (valueChangeEvent)="valueChange($event)"></app-json-form>        
        </div>
        <div class="col-md-10 text-end">
            <button type="submit"
                [ngClass]="participantForm.valid ? 'btn btn-primary' : 'btn btn-light disabled'">Next</button>
        </div>
    </div>
    </form>
    
    </div>