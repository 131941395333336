import { Injectable, Provider } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { Observable } from 'rxjs';
import { RequiredFields } from '../models/RequiredFields';
import { CreateLeadResponse, InternalLeadConfirmationModel, InternalLeadHeaderModel, InternalLeadSetupModel, InternalReferral, InternalReferringSource, InvolvedParty, localStorageIntoDB, ProviderList, StateCounty, StateNames } from '../models/vendor.model';
import { Questions } from '../models/Questions';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  baseUrl = environment.baseUri;

  constructor(private http: HttpClient) { }
  
  public getPayorIdByCode(securityCode: string| null): Observable<number> {
    return this.http.get<number>(this.baseUrl + "FCReferral/GetPayorIdByCode?securityCode=" + securityCode);
  }

  public getRequiredFields(payorkey: number): Observable<RequiredFields> {
    return this.http.get<RequiredFields>(this.baseUrl + "FCReferral/GetRequiredFields?payorKey=" + payorkey);
  }

  public getInvolvedPartiesLookup(payorkey: number): Observable<InvolvedParty[]> {
    return this.http.get<InvolvedParty[]>(this.baseUrl + "FCReferral/GetInvolvedPartiesLookup?payorKey=" + payorkey);
  }

  
  public getReferralTypesLookup(payorkey: number): Observable<InternalReferral[]> {
    return this.http.get<InternalReferral[]>(this.baseUrl + "FCReferral/GetReferralTypesLookup?payorKey=" + payorkey);
  }

  public getReferringSourcesLookup(payorkey: number): Observable<InternalReferringSource[]> {
    return this.http.get<InternalReferringSource[]>(this.baseUrl + "FCReferral/GetReferringSourcesLookup?payorKey=" + payorkey);
  }

  public getStateNames(): Observable<StateNames[]> {
    return this.http.get<StateNames[]>(this.baseUrl + "FCReferral/GetStates");
  }
  public getStateCounties(isocode : string): Observable<StateCounty[]> {
    return this.http.get<StateCounty[]>(this.baseUrl + "FCReferral/GetStateCounties?isocode=" + isocode);
  }
  public getLeadHeaders(payorkey: number): Observable<InternalLeadHeaderModel> {
    return this.http.get<InternalLeadHeaderModel>(this.baseUrl + "FCReferral/GetLeadHeaders?payorKey=" + payorkey);
  }

  public getLeadQuestion(payorkey: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + "FCReferral/GetLeadQuestion?payorKey=" + payorkey);
  }

  public getLeadConfirmation(payorkey: number): Observable<InternalLeadConfirmationModel> {
    return this.http.get<InternalLeadConfirmationModel>(this.baseUrl + "FCReferral/GetLeadConfirmation?payorKey=" + payorkey);
  }

  public createLead(payorkey: number, data : any): Observable<CreateLeadResponse> {
    return this.http.post<CreateLeadResponse>(this.baseUrl + "FCReferral/CreateLead?payorKey=" + payorkey, data);
  }

  public getLookUpProviderSpeciality(payorkey: number,): Observable<ProviderList[]> {
    return this.http.get<ProviderList[]>(this.baseUrl + "FCReferral/GetLookUpProviderSpeciality?payorKey=" + payorkey);
  }
  public getLookUpProviderType(payorkey: number,): Observable<ProviderList[]> {
    return this.http.get<ProviderList[]>(this.baseUrl + "FCReferral/GetLookUpProviderType?payorKey=" + payorkey);
  }

  public getLeadSetup(payorkey: number): Observable<InternalLeadSetupModel> {
    return this.http.get<InternalLeadSetupModel>(this.baseUrl + "FCReferral/GetLeadSetup?payorKey=" + payorkey);
  }

}

